<template>
  <div class="sample-page common-page">
    <Nav />
    <div class="page-top">
      <img src="../../image/sample-banner.png" alt="" />
    </div>
    <div class="page-about">
      <h2>立即申请免费样品，确保您的设计可行！</h2>
      <div class="text-box">
        <p style="margin-bottom: 16px">
          不确定浩亭产品性能是否可以满足您的需求？欢迎免费索取样品帮您在设计中进行测试。
        </p>
        <p>
          浩亭的产品种类繁多，我们部分产品开放样品给客户免费测试和使用，样品库会不断的添加新品供客户选样使用。
        </p>
      </div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M12 24C18.6274 24 24 18.6274 24 12C24 5.37258 18.6274 0 12 0C5.37258 0 0 5.37258 0 12C0 18.6274 5.37258 24 12 24ZM16.3536 16.3536C16.4015 16.3056 16.4377 16.2504 16.4621 16.1914C16.4861 16.1333 16.4996 16.0697 16.5 16.003L16.5 16V15.9995V8C16.5 7.72386 16.2761 7.5 16 7.5C15.7239 7.5 15.5 7.72386 15.5 8V14.7929L8.35355 7.64645C8.15829 7.45118 7.84171 7.45118 7.64645 7.64645C7.45118 7.84171 7.45118 8.15829 7.64645 8.35355L14.7929 15.5H8C7.72386 15.5 7.5 15.7239 7.5 16C7.5 16.2761 7.72386 16.5 8 16.5H15.9995H16C16.008 16.5 16.016 16.4998 16.024 16.4994C16.0811 16.4967 16.1378 16.4842 16.1914 16.4621C16.2504 16.4377 16.3056 16.4015 16.3536 16.3536Z"
          fill="white"
          fill-opacity="0.7"
        />
      </svg>
    </div>
    <div class="card-position-bottom">
      <div
        class="common-card-wrap card-top"
        v-for="(card, index) in cardList"
        :key="index"
        :style="setClassName(index)"
      >
        <div class="card-head" @click="openCard(index)">
          <div class="text" v-html="card.title"></div>
          <div class="icon">
            <van-icon
              name="arrow-down"
              :class="{ has: cardSwitch[`card0${index}`] }"
              size="20"
            />
          </div>
        </div>
        <transition name="fade">
          <div class="card-content" v-if="cardSwitch[`card0${index}`]">
            <div class="top-wrap">
              <div class="top">
                <div class="img-box">
                  <img :src="card.sample_image" alt="" />
                </div>
                <!-- <div class="tip">
                  {{ top.tip }}
                </div> -->
              </div>
            </div>
            <div
              class="small-card-wrap"
              v-if="card.smallCard && card.smallCard.length"
            >
              <div
                class="small-card"
                v-for="(smallCard, eq) in card.smallCard"
                :key="eq"
              >
                <div class="img-box">
                  <img :src="smallCard.imgUrl" alt="" />
                </div>
                <div class="about">{{ smallCard.about }}</div>
              </div>
            </div>
            <div class="btn-wrap" v-if="card.can_sample_apply">
                <div class="btn-sample" @click="openIframeForm(card)">样品申请 →</div>
            </div>

            <div class="quote">
              <div class="title">您的优势概览</div>
              <ul class="quote-cont">
                <li
                  v-for="(text, eq) in card.advantages?.split('\n')"
                  :key="eq"
                >
                  <span class="cube"></span>{{ text }}
                </li>
              </ul>
            </div>
            <div class="video-box">
              <!-- 这里放video，先放图片替代 -->
              <img :src="card.image" alt="" />
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="empty"></div>
    <van-popup
      v-model="iframeLinkShow"
      :close-on-click-overlay="false"
      :closeable="true"
      @close="closeIframeForm"
    >
      <div class="pop-form">
        <div class="iframe-box">
          <div v-if="isLoading" class="laoding-box">
            <van-loading color="#fc0">加载中...</van-loading>
          </div>
          <iframe
            :src="iframeLink"
            @load="onLoad"
            width="100%"
            height="100%"
          ></iframe>
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import Nav from "../../component/nav.vue";
import { Icon, Popup, Loading, } from "vant";
import request from "../../utils/request";
export default {
  name: "Sample",
  components: {
    Nav,
    [Icon.name]: Icon,
    [Popup.name]: Popup,
    [Loading.name]: Loading,
  },
  data() {
    return {
      cardSwitch: {
        card00: false,
        card01: false,
        card02: false,
        card03: false,
      },
      cardList: [],
      iframeLinkShow: false,
      iframeLink: "",
      isLoading: true,
    };
  },
  methods: {
    setClassName(index) {
      // if (index === 0) {
      //   return `card-top0${index}`;
      // }
      return `top: -${index * 40}px`;
    },
    openCard(index) {
      let key = `card0${index}`;
      console.log(this.cardSwitch[key], index);
      this.cardSwitch[key] = !this.cardSwitch[key];
    },
    fetchList() {
      request
        .get("/product/list", {
          params: {
            page_size: 4,
            page_num: 1,
            can_sample: 1,
          },
        }) // 假设接口地址为 /news
        .then((response) => {
          this.cardList = response.data;
          // 同时初始化cardSwitch
          if(response.data && response.data.length) {
            let cardSwitch = {}
            for(let i = 0; i < response.data.length; i ++) {
              cardSwitch[`card0${i}`] = false
            }
            this.cardSwitch = cardSwitch
          }
        })
        .catch((error) => {
          console.error("Error fetching news list:", error);
        });
    },
    onLoad() {
      this.isLoading = false;
    },
    openIframeForm(item) {
      // this.iframeLinkShow = true;
      this.iframeLink = item.sample_form;
      window.location.href = this.iframeLink
    },
    closeIframeForm() {
      console.log('close')
    }
  },
  created() {
    this.fetchList();
  },
};
</script>
<style lang="less" scoped>
.sample-page {
  .page-top {
    padding-top: 40px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .page-about {
    padding: 16px 32px 32px 32px;
    h2 {
      margin-bottom: 8px;
      color: #212027;
      font-size: 28px;
      font-style: normal;
      font-weight: 800;
      line-height: 120%; /* 33.6px */
    }
    .text-box {
      margin-bottom: 8px;
      p {
        color: #505054;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 160%; /* 22.4px */
      }
    }
  }
  .card-position-bottom {
    position: relative;
    z-index: 2;
  }
  .common-card-wrap {
    box-sizing: border-box;
    min-height: 200px;
    .card-head {
      display: flex;
      .text {
        flex: 1;
        color: #212027;
        font-size: 24px;
        font-style: normal;
        font-weight: 800;
        line-height: 120%; /* 28.8px */
      }
      .icon {
        width: 29px;
        height: 24px;
        display: flex;
        justify-content: center;
        .van-icon {
          transform: rotate(0deg) translateZ(0);
          transition: transform 0.3s;
        }
        .van-icon.has {
          transform: rotate(180deg);
          transition: transform 0.3s;
        }
      }
    }
    .card-content {
      padding-top: 24px;
      .btn-wrap {
        margin-bottom: 24px;
        display: flex;
      }
      .btn-sample {
        box-sizing: border-box;
        width: auto;
        height: 40px;
        padding: 12px 24px;
        border-radius: 40px;
        background: @theme-color;
        font-size: 14px;
        line-height: 100%;
        color: #212027;
      }
      .quote {
        margin-bottom: 24px;
        padding: 16px;
        border-radius: 16px;
        background: #f0f3f4;
        .title {
          margin-bottom: 16px;
          color: #212027;
          font-size: 18px;
          font-style: normal;
          font-weight: 600;
          line-height: 120%; /* 21.6px */
        }
        .quote-cont {
          li {
            padding-left: 4px;
            margin-bottom: 16px;
            color: #212027;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 22.5px */
            .cube {
              margin-right: 6px;
              display: inline-block;
              vertical-align: middle;
              width: 4px;
              height: 4px;
              border-radius: 1px;
              background: @theme-color;
            }
          }
        }
      }
      .video-box {
        padding-bottom: 40px;
        width: 100%;
        height: 190px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .top {
        margin-bottom: 24px;
        .img-box {
          img {
            width: 220px;
            height: 290px;
            object-fit: contain;
          }
        }
      }
      .img-box {
        display: flex;
        justify-content: center;
        width: 100%;
        img {
          display: block;
          width: 100%;
          height: 240px;
          object-fit: cover;
        }
      }
      .tip {
        color: #212027;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 150%; /* 21px */
      }
      .about {
        background: #f0f3f4;
        padding: 16px 16px 24px 16px;
        color: #212027;
        font-size: 14px;
        font-style: normal;
        font-weight: 300;
        line-height: 22px;
      }
      .small-card {
        margin-bottom: 24px;
        overflow: hidden;
        border-radius: 24px;
        background: #fff;
      }
    }
    .fade-enter-active {
      animation: fade-in ease-in-out 0.5s;
      //   overflow: hidden;
      //   -webkit-transition: height 0.3s ease-in-out;
      //   transition: height 0.3s ease-in-out;
      //   will-change: height;
    }
    .fade-leave-active {
      animation: fade-in 0.5s reverse;
    }
    @keyframes fade-in {
      0% {
        height: 0;
        opacity: 0;
        // transform: translateY(-150%);
      }
      50% {
        opacity: 0;
      }
      100% {
        // transform: translateY(0);
        opacity: 1;
        height: 500px;
      }
    }
  }
  .common-card-wrap.card-top {
    position: relative;
    background: linear-gradient(180deg, #f0f5f7 0%, #fff 87.52%);
    box-shadow: 0px 0px 24px 0px #d7d9dc;
  }
  .empty {
    position: absolute;
    bottom: 0;
    height: 140px;
    width: 100%;
    background: #fff;
  }
}
</style>
